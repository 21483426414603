import { Stack, styled } from '@mui/material';
import { colors } from '../theme';

export const VerifyOtpStyleWrapper = styled(Stack)(({ theme }) => ({
    // Otpinput screen styles
    '& .otpContainer': {
        display: 'flex',
        gap: theme.spacing(1.25),
        marginBottom: theme.spacing(2.25),
        justifyContent: 'center',
        '& .MuiBox-root': {
            position: 'relative',
            '& input': {
                width: theme.spacing(5.25),
                height: theme.spacing(5.25),
                background: theme.palette.mode === 'dark' ? colors.darkBgButton : colors.lightBgButton,
                border: `2px solid ${theme.palette.mode === 'dark' ? colors.blackColor : colors.white}`,
                color: theme.palette.mode === 'dark' ? colors.white : colors.blackColor,
                textAlign: 'center',
                boxShadow: '6px 6px 16px 0px #00000040',
                fontSize: theme.spacing(2.25),
                borderRadius: theme.spacing(2),
                padding: theme.spacing(0),
                fontWeight: 700,
            },
            '& .MuiInputBase-root': {
                padding: theme.spacing(0.25),
            },
            '& .Mui-focused': {
                borderRadius: theme.spacing(2),
                background:
                    'linear-gradient(90deg, #2FABE1 0%, #59BDE7 5.13%, #89D1ED 11.85%, #A8DEF1 16.98%, #B3E3F3 19.87%, #FDCC3F 40.5%, #FF8524 61.36%, #E03C23 80.8%, #441212 100%)',
                '& input': {
                    border: '2px solid transparent',
                },
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderBottom: theme.spacing(0),
            },
        },
    },
}));
