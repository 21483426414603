export const isNumber = (val: any) => {
    return /^\d*$/.test(val);
};

export const getInitials = (name: string) => {
    if (!name) {
        return '';
    }
    const [firstName, lastName] = name.trim().split(' ');
    return `${firstName[0]}${lastName ? lastName[0] : ''}`.toUpperCase();
};
