import React, { ReactElement } from 'react';
import { Button } from '@mui/material';
import { ButtonWrapper } from './ButtonStyles';

interface IButton {
    disableElevation?: boolean;
    startIcon?: ReactElement;
    endIcon?: ReactElement;
    fullWidth?: boolean;
    disabled?: boolean;
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    onClick?: () => void;
    onKeyPress?: () => void;
    variant?: 'text' | 'contained' | 'outlined';
    buttonName?: string;
    sx?: object;
    size?: 'small' | 'medium' | 'large';
    className?: string;
    type?: any;
}
export function ButtonComponent({
    disableElevation = true,
    startIcon,
    endIcon,
    variant,
    buttonName,
    disabled,
    color,
    onClick,
    fullWidth,
    size,
    className,
    onKeyPress,
    type,
}: IButton) {
    return (
        <ButtonWrapper>
            <Button
                startIcon={startIcon}
                disableElevation={disableElevation}
                endIcon={endIcon}
                variant={variant}
                disabled={disabled}
                fullWidth={fullWidth}
                // color={color}
                onClick={onClick}
                onKeyPress={onKeyPress}
                size={size}
                className={className}
                type={type ? type : 'submit'}
            >
                {buttonName}
            </Button>
        </ButtonWrapper>
    );
}
