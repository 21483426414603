export function PasswordIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="143" height="80" viewBox="0 0 143 80" fill="none">
            <path
                d="M129.45 27.6667C131.288 27.6667 133.052 28.3892 134.361 29.6784L139.594 34.8303C142.35 37.5436 142.384 41.9778 139.669 44.7324L124.412 60.2124C121.68 62.9844 117.211 62.9955 114.465 60.2372L112.56 58.3235C109.042 54.7894 103.321 54.7894 99.8034 58.3235L97.8701 60.2656C95.1339 63.0143 90.6843 63.0143 87.9481 60.2656L80.9505 53.236C80.3753 52.6582 79.5936 52.3333 78.7783 52.3333C77.4685 52.3333 76.3357 53.1618 75.873 54.3414C70.2071 68.7856 56.197 79 39.8182 79C18.4084 79 1 61.5187 1 40C1 18.4813 18.4084 1 39.8182 1C54.746 1 67.7057 9.48406 74.1751 21.9241C75.8771 25.1969 79.1398 27.6667 82.9877 27.6667H129.45ZM18.9091 40C18.9091 51.548 28.3116 61 39.8182 61C51.3248 61 60.7273 51.548 60.7273 40C60.7273 28.452 51.3248 19 39.8182 19C28.3116 19 18.9091 28.452 18.9091 40Z"
                stroke="white"
                strokeWidth="2"
            />
        </svg>
    );
}
