import { Box, Grid2, Stack, Typography } from '@mui/material';
import * as React from 'react';
import MemoryIcon from '@mui/icons-material/Memory';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import WaterDamageOutlinedIcon from '@mui/icons-material/WaterDamageOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import { useTranslation } from 'react-i18next';
import { SystemMode } from './system-mode';
import { Temparature } from './temparature';
import { ListItemWrapper, StyleWrapper } from './home-styles';
import DeviceThermostatOutlinedIcon from '@mui/icons-material/DeviceThermostatOutlined';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import { getTimeAgo } from '../../utils/helper';
import { colors, GET_DASHBOARD_EARNINGS, GET_DASHBOARD_METRICS } from '@watter/shared-components';
import { client } from '../../service/apolloClient';
import { setIsRefresh, setMachineId, setSensorTime } from '../../store/reducers/heaterSlice';
import { hideLoader, showLoader } from '../../store/reducers/appSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

interface HomeProps { }
export const Home: React.FC<HomeProps> = () => {
    const { t } = useTranslation();
    const [showHeater, setShowHeater] = React.useState<boolean>(true);
    const [dashboardEarnings, setDashboardEarnings] = React.useState<any>(null);
    const [dashboardMetrics, setDashboardMetrics] = React.useState<any>(null);
    const isRefresh = useSelector((state: any) => state.heater.is_refresh);
    const dispatch = useDispatch();
    setTimeout(() => {
        setShowHeater(false);
    }, 3000);
    React.useEffect(() => {
        fetchDashboardEarnings();
        fetchDashboardMetrics();
    }, [isRefresh])
    const fetchDashboardEarnings = async () => {
        try {
            dispatch(showLoader());
            const { data } = await client.query({
                query: GET_DASHBOARD_EARNINGS,
                fetchPolicy: 'network-only',
            });
            if (data && data?.getDashboardEarnings) {
                setDashboardEarnings(data?.getDashboardEarnings);
                dispatch(setMachineId(data?.getDashboardEarnings?.device_id));
            }

        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            dispatch(setIsRefresh(false));
            dispatch(hideLoader());
        }
    };
    const fetchDashboardMetrics = async () => {
        try {
            dispatch(showLoader());

            const { data } = await client.query({
                query: GET_DASHBOARD_METRICS,
                fetchPolicy: 'network-only',
            });
            if (data && data?.getDashboardMetrics) {
                setDashboardMetrics(data?.getDashboardMetrics);
                dispatch(setSensorTime(data?.getDashboardMetrics?.sensor_timestamp));
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            dispatch(hideLoader());
        }
    };
    const getGpuColor = (value: number) => {
        if (value <= 30) return colors.lightBlue;
        if (value <= 60) return 'green';
        if (value <= 80) return 'orange';
        return colors.astric;
    };
    const getWaterTempColor = (value: number) => {
        if (value <= 32) return colors.lightBlue;
        if (value <= 70) return 'green';
        if (value <= 100) return 'orange';
        return colors.astric;
    };
    const getMonthlyEarnings = () => {
        const earnings = dashboardEarnings?.gpu_earning_month + dashboardEarnings?.storage_earning_month;
        return earnings ? earnings.toFixed(2) : "0.00";
    }
    const getTotalEarnings = () => {
        const earnings = dashboardEarnings?.gpu_earning_total + dashboardEarnings?.storage_earning_total;
        return earnings ? earnings.toFixed(2) : "0.00";
    }
    return (
        <StyleWrapper>
            <Box textAlign="center" className={`indexBody ${showHeater && 'indexBodyLoader'}`} pb={2}>
                <Grid2 container height="100%">
                    {!showHeater && (
                        <Grid2 size={{ xs: 12, lg: 5 }} className="listBlock" paddingRight={{ xs: 0, lg: 2 }}>
                            <ListItemWrapper p={2} className="wrapperItems" bgcolor="info.50" boxShadow={1} borderRadius={4} mt={2}>
                                <Typography variant="subtitle2" align='left'>
                                    Machine: {dashboardEarnings?.machine_name}
                                </Typography>
                                <Stack direction="row" justifyContent="space-between">
                                    <Box textAlign="start">
                                        <Typography variant="h4" fontFamily={'good_sansbold'}>${getMonthlyEarnings()}</Typography>
                                        <Typography variant="subtitle2" pb={1}>
                                            {t('this_month')}
                                        </Typography>
                                    </Box>
                                    <Box textAlign="end">
                                        <Typography variant="h4" fontFamily={'good_sansbold'}>${getTotalEarnings()}</Typography>
                                        <Typography variant="subtitle2" pb={1}>
                                            {t('life_time')}
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Box display="flex" pt={1} alignItems="center" justifyContent="space-between">
                                    <Typography variant="subtitle2" display="flex" alignItems="center" columnGap={1.5} fontSize={'8px'} fontFamily={'good_sansbold'}>
                                        {'MY EARNINGS'}
                                    </Typography>
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="subtitle2" display="flex" alignItems="center">
                                            {t('last_updated')}:
                                        </Typography>
                                        <Typography variant='subtitle2' fontWeight={600} ml={0.5}>{getTimeAgo(dashboardEarnings?.gpu_earnings_updated_at)}</Typography>
                                    </Box>

                                </Box>
                            </ListItemWrapper>
                            <Box pt={4} display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="subtitle2" display="flex" alignItems="center" columnGap={1.5} fontFamily={'good_sansbold'}>
                                    {t('watter_readings')}
                                </Typography>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="subtitle2" display="flex" alignItems="center">
                                        {t('last_updated_on')}:
                                    </Typography>
                                    <Typography variant='subtitle2' fontWeight={600} ml={0.5}>{getTimeAgo(dashboardMetrics?.sensor_timestamp)}</Typography>
                                </Box>

                            </Box>
                            <ListItemWrapper className="wrapper" p={2} bgcolor="info.50" boxShadow={1} borderRadius={4} mt={2}>
                                <Stack direction="row" justifyContent="space-between">
                                    <Box textAlign="start">
                                        <Typography variant="h4" fontWeight={700}>45(gal)</Typography>
                                        <Typography variant="subtitle2" pb={1} className='subtext'>
                                            {t('gallons')}
                                        </Typography>
                                    </Box>
                                    <Box textAlign="end" zIndex={1}>
                                        <Typography variant="h4" fontWeight={700}>1746(gal)</Typography>
                                        <Typography variant="subtitle2" pb={1} className='subtext'>
                                            {t('life_time_gallons')}
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Box pt={'3px'} display="flex" alignItems="center" justifyContent="space-between">
                                    <Typography variant="subtitle2" display="flex" alignItems="center" columnGap={1.5} fontSize={'8px'} fontFamily={'good_sansbold'} sx={{ textTransform: 'uppercase' }}>
                                        {t('watter_usage')}
                                    </Typography>
                                </Box>
                                <WaterDamageOutlinedIcon />
                            </ListItemWrapper>
                            <Grid2 container spacing={2}>
                                <Grid2 size={6}>
                                    <ListItemWrapper className="wrapper" p={2} bgcolor="info.50" boxShadow={1} borderRadius={4} mt={2}>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Box textAlign="start">
                                                <Typography variant="h4" fontWeight={700} sx={{ color: getGpuColor(dashboardMetrics?.gpu_utilization) }}>{dashboardMetrics?.gpu_utilization}%</Typography>
                                            </Box>
                                        </Stack>
                                        <Box pt={'26px'} display="flex" alignItems="center" justifyContent="space-between">
                                            <Typography fontFamily={'good_sansbold'} variant="subtitle2" display="flex" alignItems="center" columnGap={1.5} fontSize={'8px'} sx={{ textTransform: 'uppercase' }}>
                                                {t('gpu_util')}
                                            </Typography>
                                        </Box>
                                        <MemoryIcon />
                                    </ListItemWrapper>
                                </Grid2>
                                <Grid2 size={6}>

                                    <ListItemWrapper className="wrapper" p={2} bgcolor="info.50" boxShadow={1} borderRadius={4} mt={2}>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Box textAlign="start">
                                                <Typography variant="h4" fontWeight={700} sx={{ color: getWaterTempColor(dashboardMetrics?.water_temperature) }}>{dashboardMetrics?.water_temperature}°F</Typography>
                                                {/* <Typography variant="subtitle2" pb={1} className='subtext'>
                                                    {'Medium(90-105°F)'}
                                                </Typography> */}
                                            </Box>
                                        </Stack>
                                        <Box pt={'26px'} display="flex" alignItems="center" justifyContent="space-between">
                                            <Typography fontFamily={'good_sansbold'} variant="subtitle2" display="flex" alignItems="center" columnGap={1.5} fontSize={'8px'} sx={{ textTransform: 'uppercase' }}>
                                                {t('Water_temperature')}
                                            </Typography>
                                        </Box>
                                        <DeviceThermostatOutlinedIcon />
                                    </ListItemWrapper>
                                </Grid2>
                            </Grid2>
                            <ListItemWrapper px={2} py={3} className="wrapper" bgcolor="info.50" boxShadow={1} borderRadius={4} mt={2}>
                                <Stack alignItems={'flex-start'}>
                                    <Typography variant="h4" fontFamily={'good_sansbold'}>{t('hybrid')}</Typography>
                                    <Typography variant="subtitle2" pb={1} sx={{ textTransform: 'uppercase' }}>
                                        {t('system_mode')}
                                    </Typography>
                                </Stack>
                                <BoltOutlinedIcon />
                            </ListItemWrapper>
                        </Grid2>

                    )}
                    <Grid2 size={showHeater ? 12 : 7} display={{ xs: 'none', lg: 'block' }} className="assetBlock">
                        {showHeater && <Typography variant="h4">{t('installation_pending')}</Typography>}
                        <Box />
                        {/* <Box m={'auto'} width={showHeater ? '500px' : '33%'}><img src={assetLoader} width={'100%'} /></Box> */}
                    </Grid2>
                </Grid2>
            </Box>
        </StyleWrapper>
    );
};
