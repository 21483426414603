import { combineReducers } from 'redux';
import { toastReducer } from './toastSlice';
import { userReducer } from './userSlice';
import { appReducer } from './appSlice';
import { heaterReducer } from './heaterSlice';

export const rootReducer = combineReducers({
    app: appReducer,
    toast: toastReducer,
    user: userReducer,
    heater: heaterReducer
});
