import { createSlice } from '@reduxjs/toolkit';
import { revertAll } from '../actions';

export interface IHeaterState {
    is_refresh: boolean;
    sensor_time: string;
    machine_id: string;
}

const initialState: IHeaterState = {
    is_refresh: false,
    sensor_time: '',
    machine_id: ''
};

const heaterSlice = createSlice({
    name: 'heater',
    initialState,
    reducers: {
        setIsRefresh: (state, action) => {
            state.is_refresh = action.payload;
        },
        setSensorTime: (state, action) => {
            state.sensor_time = action.payload;
        },
        setMachineId: (state, action) => {
            state.machine_id = action.payload;
        }
    },
    extraReducers: builder => builder.addCase(revertAll, () => initialState),
});

export const {
    setIsRefresh,
    setSensorTime,
    setMachineId
} = heaterSlice.actions;

export const heaterReducer = heaterSlice.reducer;
