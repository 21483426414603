import { gql } from '@apollo/client';

const GET_USERS = gql`
    query getUser {
        getUser {
            application_type
            cognito_id
            email
            first_name
            id
            last_logged_at
            last_name
            phone_number
            profile_image
            status
            user_addresses {
                address_type
                city
                country
                id
                state
                street
                user_id
                zip_code
            }
            username
        }
    }
`;

const GET_USER_SYSTEM_SETTINGS = gql`
    query getUserSettings {
        getUserSettings {
            theme
            user_id
            id
        }
    }
`;

const GET_DASHBOARD_EARNINGS = gql`
query GetDashboardEarnings {
  getDashboardEarnings {
    id
    device_id
    machine_name
    gpu_earning_month
    gpu_earning_total
    storage_earning_month
    storage_earning_total
    water_usage_month
    water_usage_total
    gpu_earnings_updated_at
    power
    internet
  }
}`;

const GET_DASHBOARD_METRICS = gql`
query GetDashboardMetrics {
  getDashboardMetrics {
    water_temperature
    gpu_utilization
    internet
    power
    sensor_timestamp
    device_id
  }
}`;

export { GET_USERS, GET_USER_SYSTEM_SETTINGS, GET_DASHBOARD_EARNINGS, GET_DASHBOARD_METRICS };
