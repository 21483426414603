import { gql } from '@apollo/client';

const GET_INSTALLER_INSTALLATIONS_LIST = gql`
    query InstallerInstallationsList($limit: Int!, $page: Int!, $id: Int!) {
        getInstallerInstallationsList(limit: $limit, page: $page, id: $id) {
            data {
                id
                username
                state
                city
                street
                zip_code
                installation_started_at
                installation_date
                installation_time
                status
            }
            total_count
        }
    }
`;

export { GET_INSTALLER_INSTALLATIONS_LIST };
