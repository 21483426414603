import { Dialog, styled } from '@mui/material';
import { Padding } from '@mui/icons-material';
import { colors } from '../theme';

export const ModalUI = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        zIndex: 0,
    },
    '&.changePassword': {
        '& .MuiDialog-paper': {
            padding: theme.spacing(2),
            width: '100%',
            '& .MuiDialogContent-root': {
                paddingBottom: 0,
                paddingTop: theme.spacing(2.5),
                '& .MuiFormControl-root': {
                    marginBottom: theme.spacing(0.5),
                    '& .MuiInputBase-root': {
                        '& .MuiInputBase-input': {
                            padding: theme.spacing(1.25, 0),
                        },
                        '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
                            marginLeft: theme.spacing(0),
                            paddingRight: theme.spacing(2),
                        },
                    },
                },
                '& form': {
                    '& h6': {
                        color: theme.palette.mode === 'dark' ? colors.info : colors.gray,
                    },
                },
            },
            '& .MuiDialogActions-root': {
                padding: theme.spacing(3),
                paddingTop: 0,
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                },
            },
        },
    },
    '&.cstVerifyModal': {
        '& .cstResendBtn': {
            color: theme.palette.mode === 'dark' ? colors.white : colors.blackColor,
        },
        '& .MuiFormControl-root': {
            '& .MuiInputBase-root': {
                '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                },
            },
        },
        [theme.breakpoints.down('sm')]: {
            '& .MuiPaper-root': {
                minWidth: '95%',
                padding: theme.spacing(2.25),
                '& .MuiDialogContent-root': {
                    padding: theme.spacing(2.5, 0),
                    '& .otpContainer ': {
                        gap: theme.spacing(1),
                    },
                },
            },
        },
    },
    '&.cstProfileModal': {
        '& .MuiDialogTitle-root': {
            padding: theme.spacing(0, 3),
            paddingBottom: theme.spacing(2),
            textAlign: 'center',
        },
        '& .ReactCrop--fixed-aspect': {
            overflow: 'hidden',
        },
        '& .ReactCrop__crop-selection': {
            boxShadow: '-8px -20px 30px 1300px #120c0c6b',
        },
        '& .MuiPaper-root': {
            height: '60vh',
            maxWidth: '60vw',
            '& .MuiDialogContent-root': {
                height: 'calc(60vh - 190px)',
                '& .MuiDialogContentText-root': {
                    height: '100%',
                    '& .MuiBox-root': {
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        '& .ReactCrop--fixed-aspect,.ReactCrop__child-wrapper,img': {
                            height: '100%',
                        },
                    },
                },
            },
        },
    },
    '& .MuiDialogActions-root': {
        '& .MuiButtonBase-root': {
            '&:focus::after': {
                content: "''",
                background:
                    'linear-gradient(90deg, #2FABE1 0%, #59BDE7 5.13%, #89D1ED 11.85%, #A8DEF1 16.98%, #B3E3F3 19.87%, #FDCC3F 40.5%, #FF8524 61.36%, #E03C23 80.8%, #441212 100%)',
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: -1,
                inset: 0,
                borderRadius: '28px',
                transform: 'scale(1.02, 1.06)',
            }
        }
    }
}));
