const regex = {
    mobileRegExp: /^(\+\d{1,3}[- ]?)?\d{10}$/,
    emailRegExp: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    passwordRegExp:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^\$\*\.\[\]\{\}\(\)\?\-"!@#%&\/\\,><':;|_~`+=])(?=.{8,20}$)/,
};

enum CognitoCallbacks {
    CONFIRM_SIGN_IN_WITH_SMS_CODE = 'CONFIRM_SIGN_IN_WITH_SMS_CODE',
    CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED = 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
    DONE = 'DONE',
    CONFIRM_SIGN_IN_WITH_EMAIL_CODE = 'CONFIRM_SIGN_IN_WITH_EMAIL_CODE',
    CONFIRM_RESET_PASSWORD_WITH_CODE = 'CONFIRM_RESET_PASSWORD_WITH_CODE',
    CONFIRM_ATTRIBUTE_WITH_CODE = 'CONFIRM_ATTRIBUTE_WITH_CODE',
}
enum CognitoErrorCodes {
    NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException',
    USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException',
    CODE_MISMATCH_EXCEPTION = 'CodeMismatchException',
    SIGN_IN_EXCEPTION = 'SignInException',
    EMPTY_SIGNIN_USER_NAME = 'EmptySignInUsername',
    RESET_PASSWORD_EXCEPTION = 'RESET_PASSWORD_EXCEPTION',
    INVALID_PARAM_EXCEPTION = 'InvalidParameterException',
    USER_HAS_NO_ACCESS_EXCEPTION = 'UserLambdaValidationException',
    NEW_PASSWORD_POLICY_VIOLATION_EXCEPTION = 'PasswordHistoryPolicyViolationException',
}
enum PasswordColorCodeStrength {
    LOWER_STRENGTH = 1,
    MINIMUM_STRENGTH = 2,
    MEDIUM_STRENGTH = 3,
    HIGH_STRENGTH = 4,
}

enum appType {
    Customer = '1',
    Installer = '2',
    Admin = '3',
}

export { appType, PasswordColorCodeStrength, regex, CognitoCallbacks, CognitoErrorCodes };
