import Pagination from '@mui/material/Pagination';
import React from 'react';

interface PaginationProps {
    totalCount: number;
    rowsPerPage: number;
    currentPage: number;
    onPageChange: (newPage: number) => void;
}

export function CustomPagination(props: PaginationProps) {
    const { totalCount, rowsPerPage, currentPage, onPageChange } = props;
    const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
        onPageChange(newPage);
    };
    return <Pagination count={Math.ceil(totalCount / rowsPerPage)} page={currentPage} onChange={handlePageChange} />;
}
