import React from 'react';
import { Box, Drawer, IconButton, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { signOut } from 'aws-amplify/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { CommonModal } from '@watter/shared-components';
import { hideLoader, showLoader } from '../../store/reducers/appSlice';
import { revertAll } from '../../store/actions';
import { NavigationConfig } from '../../utils/navigation-constants';
import { SideMenuStyles } from './sidemenu-styles';
import { LogoIcon } from '../icons/logo-icon';

interface IProps {
    menuOpen: any;
    menuClose: any;
}
export const SideMenu: React.FC<IProps> = ({ menuOpen, menuClose }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [activeMenu, setActiveMenu] = React.useState<string>(NavigationConfig.Home);
    const apolloClient = useApolloClient();
    const location = useLocation();
    const handleLogout = () => {
        dispatch(showLoader());
        signOut()
            .then((res: any) => {
                console.warn(res, '==> res in signout');
                dispatch(revertAll());
                localStorage.removeItem('authToken');
                sessionStorage.clear();
                apolloClient.clearStore();
                navigate(NavigationConfig.Login);
            })
            .catch((err: any) => {
                console.error('err SignOut: ', err);
            })
            .finally(() => {
                dispatch(hideLoader());
            });
    };
    const pathNavigateClick = (value: any) => {
        navigate(value);
        setActiveMenu(value);
    };
    const MenuItems = [
        {
            pathValue: NavigationConfig.Home,
            title: t('dashboard'),
            icon: <DashboardOutlinedIcon />,
        },
        {
            pathValue: NavigationConfig.ProfileSetting,
            title: t('profile_settings'),
            icon: <PermIdentityOutlinedIcon />,
        },
        // {
        //     pathValue: NavigationConfig.HeaterDetails,
        //     title: t('heater_details'),
        //     icon: <DetailsIcon />,
        // },
        // {
        //     pathValue: NavigationConfig.NotificationSetting,
        //     title: t('notification_settings'),
        //     icon: <UserSettingIcon />,
        // },
    ];

    React.useEffect(() => {
        const menuItem = MenuItems.filter((item: any) => item.pathValue === location.pathname);
        setActiveMenu(menuItem[0]?.pathValue);
    }, [location.pathname]);

    const navigateToDashboard = () => {
        navigate(NavigationConfig.Home);
        setActiveMenu(t('dashboard'));
    };
    const matches = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const sidemenuContent = () => {
        return (
            <SideMenuStyles height="100%">
                <Box>
                    <Box className="logo" textAlign="center" onClick={() => navigateToDashboard()}>
                        <LogoIcon />
                    </Box>
                    <Stack className="listItems" height="100%" alignItems={{ xs: 'flex-start', lg: 'center' }}>
                        {MenuItems.map(items => (
                            <Tooltip title={items.title} arrow placement="right">
                                <Box
                                    display={{ xs: 'flex', lg: 'block' }}
                                    mb={{ xs: 2, lg: 0 }}
                                    alignItems="center"
                                    columnGap={1}
                                    onClick={() => pathNavigateClick(items.pathValue)}
                                >
                                    <IconButton
                                        className={activeMenu === items.pathValue ? 'active' : ''}
                                        sx={{ display: 'block' }}
                                    >
                                        {items.icon}
                                    </IconButton>
                                    {matches && (
                                        <Typography
                                            sx={{ cursor: 'pointer' }}
                                            variant="subtitle1"
                                            fontFamily="good_sansmedium"
                                        >
                                            {items.title}
                                        </Typography>
                                    )}
                                </Box>
                            </Tooltip>
                        ))}
                    </Stack>
                </Box>
                <Box className="listItems" display="flex" justifyContent="center">
                    <Tooltip title={t('logout')} arrow placement="right">
                        <Box
                            display={{ xs: 'flex', lg: 'block' }}
                            mb={{ xs: 2, lg: 0 }}
                            alignItems="center"
                            columnGap={1}
                            onClick={() => setModalOpen(true)}
                        >
                            <IconButton>
                                <LogoutIcon />
                            </IconButton>
                            {matches && (
                                <Typography sx={{ cursor: 'pointer' }} variant="subtitle1" fontFamily="good_sansmedium">
                                    {t('logout')}
                                </Typography>
                            )}
                        </Box>
                    </Tooltip>
                </Box>
                <CommonModal
                    modalOpen={modalOpen}
                    header={t('logout_text')}
                    cancelBtnName={t('cancel')}
                    AcceptBtnName={t('yes')}
                    closePopup={() => setModalOpen(false)}
                    acceptBtn={handleLogout}
                />
            </SideMenuStyles>
        );
    };
    return (
        <>
            {matches ? (
                <Drawer open={menuOpen} onClose={menuClose}>
                    {sidemenuContent()}
                </Drawer>
            ) : (
                <>{sidemenuContent()}</>
            )}
        </>
    );
};
