export function GoogleIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M24.0002 12.2728C24.0002 11.4219 23.9223 10.6037 23.7776 9.81824H12.2451V14.4601H18.8351C18.5512 15.9601 17.6885 17.231 16.3917 18.0819V21.0928H20.349C22.6644 19.0037 24.0002 15.9273 24.0002 12.2728Z" />
            <path d="M12.2447 24C15.5509 24 18.3227 22.9255 20.3486 21.0928L16.3913 18.0818C15.2948 18.8018 13.8922 19.2273 12.2447 19.2273C9.05549 19.2273 6.35605 17.1164 5.39315 14.28H1.30225V17.3891C3.31709 21.3109 7.45809 24 12.2447 24Z" />
            <path d="M5.39332 14.2801C5.14842 13.5601 5.00928 12.791 5.00928 12.0001C5.00928 11.2091 5.14842 10.4401 5.39332 9.72005V6.61096H1.30241C0.445269 8.28317 -0.000755428 10.1287 9.6043e-07 12.0001C9.6043e-07 13.9364 0.473099 15.7691 1.30241 17.3891L5.39332 14.2801Z" />
            <path d="M12.2447 4.77273C14.0425 4.77273 15.6566 5.37818 16.9256 6.56727L20.4377 3.12545C18.3171 1.18909 15.5453 0 12.2447 0C7.45809 0 3.31709 2.68909 1.30225 6.61091L5.39315 9.72C6.35605 6.88364 9.05549 4.77273 12.2447 4.77273Z" />
        </svg>
    );
}
