export const getTimeAgo = (utcTime: string | number | Date) => {
    const date: any = new Date(utcTime);
    const now: any = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) return `${diffInSeconds} Sec${diffInSeconds > 1 ? "s" : ""} Ago`;
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) return `${diffInMinutes} Min${diffInMinutes > 1 ? "s" : ""} Ago`;
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours} Hour${diffInHours > 1 ? "s" : ""} Ago`;
    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) return `${diffInDays} Day${diffInDays > 1 ? "s" : ""} Ago`;
    const diffInWeeks = Math.floor(diffInDays / 7);
    if (diffInWeeks < 4) return `${diffInWeeks} Week${diffInWeeks > 1 ? "s" : ""} Ago`;
    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMonths < 12) return `${diffInMonths} Month${diffInMonths > 1 ? "s" : ""} Ago`;
    const diffInYears = Math.floor(diffInDays / 365);
    return `${diffInYears} Year${diffInYears > 1 ? "s" : ""} Ago`;
};

export const isLessThan15Seconds = (utcTime: string | number | Date) => {
    const date: any = new Date(utcTime);
    const now: any = new Date();

    const diffInSeconds = Math.abs((now - date) / 1000);

    return diffInSeconds < 15;
};