export interface INavigationConfig {
    [x: string]: any;
    Login: string;
    Home: string;
    LoginOtpScreen: string;
    PasswordSetup: string;
    NotificationSetting: string;
    ProfileSetting: string;
    HeaterDetails: string;
    ForgotPassword: string;
}
export const NavigationConfig: INavigationConfig = {
    Login: '/login',
    Home: '/home',
    LoginOtpScreen: '/verify-login-otp',
    PasswordSetup: '/set-password',
    NotificationSetting: '/notification-setting',
    ProfileSetting: '/profile-setting',
    HeaterDetails: '/heater-details',
    ForgotPassword: '/forgot-password',
};
