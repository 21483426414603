import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { store } from '../store/index';
import { nanoid } from 'nanoid';
import { openToast } from '../store/reducers/toastSlice';
// GraphQL API endpoint
const httpLink = createHttpLink({
    uri: 'https://dev.api.wattertechnologies.com/graphql',
});

//  Authorization token to the headers
const authLink = setContext((_, { headers }) => {
    const token = store.getState().app?.session?.token;
    const traceId = nanoid();
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
            'x-trace-id': `WW-${traceId}`,
        },
    };
});

// Error handling link
const errorLink = onError(({ networkError }) => {
    if (networkError && networkError.message.includes('Failed to fetch')) {
        console.error('No internet connection');
        store.dispatch(
            openToast({
                show: true,
                message: 'No Internet connection, please try again later',
                severity: 'error',
                isAlertOpen: true,
            }),
        );
    }
});

// Combine links
const link = errorLink.concat(authLink).concat(httpLink);

export const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
});
