import { cognitoUserPoolsTokenProvider } from '@aws-amplify/auth/cognito';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSession } from '../../store/reducers/appSlice';
import { updateLoginStatus } from '../../store/reducers/userSlice';
import { INavigationConfig, NavigationConfig } from '../../utils/navigation-constants';

const navConfig: INavigationConfig = NavigationConfig;

export const useHandleLoginOnSuccess = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleLoginOnSuccess = async () => {
        const loginResponse = await cognitoUserPoolsTokenProvider.authTokenStore.loadTokens();

        if (loginResponse) {
            dispatch(
                setSession({
                    token: loginResponse?.idToken?.toString(),
                    payload: loginResponse?.idToken?.payload,
                }),
            );
            dispatch(updateLoginStatus(true));
            navigate(navConfig.Home);
        }
    };

    return { handleLoginOnSuccess };
};
