import { gql } from '@apollo/client';

const UPDATE_USER = gql`
    mutation UpdateUser($input: UpdateUser!) {
        updateUser(input: $input) {
            email
            first_name
            id
            last_name
            phone_number
            status
            profile_image
            user_addresses {
                address_type
                city
                country
                id
                state
                street
                user_id
                zip_code
            }
        }
    }
`;

const UPDATE_SETTINGS_MUTATION = gql`
    mutation UpdateSettings($input: UpdateSettings!) {
        updateSettings(input: $input) {
            allow_led_light
            allow_notifications
            allow_sound
            allow_vibration
            createdAt
            floating_notifications
            id
            lock_screen_notifications
            show_notifications
            theme
            user_id
        }
    }
`;

export { UPDATE_USER, UPDATE_SETTINGS_MUTATION };
