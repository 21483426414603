export function VerifyIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="82" height="101" viewBox="0 0 82 101" fill="none">
            <path
                d="M37.7581 2.07401L37.7604 2.07318C39.8419 1.30084 42.1502 1.30285 44.2218 2.11672L44.2318 2.12064L44.2419 2.12435L74.9919 13.4501L74.9945 13.451C78.6055 14.7697 81 18.1752 81 21.9246V45.5828C81 68.8788 66.0599 90.6906 45.371 98.1353C42.548 99.1512 39.452 99.1512 36.629 98.1353C15.9401 90.6906 1 68.8788 1 45.5828V21.9246C1 18.131 3.38934 14.7685 7.01204 13.3983C7.01332 13.3978 7.0146 13.3973 7.01589 13.3968L37.7581 2.07401Z"
                stroke="white"
                strokeWidth="2"
            />
            <path
                d="M41 70.298C38.1812 70.298 35.875 68.0329 35.875 65.2644C35.875 62.4958 38.1812 60.2307 41 60.2307C43.8188 60.2307 46.125 62.4958 46.125 65.2644C46.125 68.0329 43.8188 70.298 41 70.298ZM41 50.1634C38.1812 50.1634 35.875 47.8983 35.875 45.1297V30.0288C35.875 27.2603 38.1812 24.9951 41 24.9951C43.8188 24.9951 46.125 27.2603 46.125 30.0288V45.1297C46.125 47.8983 43.8188 50.1634 41 50.1634Z"
                fill="white"
            />
        </svg>
    );
}
