import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    IconButton,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useColorScheme,
    useMediaQuery,
} from '@mui/material';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import ContrastOutlinedIcon from '@mui/icons-material/ContrastOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import ElectricalServicesOutlinedIcon from '@mui/icons-material/ElectricalServicesOutlined';
import WifiOutlinedIcon from '@mui/icons-material/WifiOutlined';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { userEnums } from '@watter/shared-types';
import { useLocation } from 'react-router-dom';
import {
    colors,
    GET_USER_SYSTEM_SETTINGS,
    UPDATE_SETTINGS_MUTATION,
    getInitials,
    GET_USERS,
} from '@watter/shared-components';
import { getUrl } from 'aws-amplify/storage';
import { NavigationConfig } from '../../utils/navigation-constants';
import { StyleWrapper } from '../../pages/home/home-styles';
import { HeaderStyles } from './header.style';
import { client } from '../../service/apolloClient';
import { RootState } from '../../store';
import { setUserName, setUserImageLink, setUserId } from '../../store/reducers/userSlice';
import { isLessThan15Seconds } from '../../utils/helper';
import RefreshIcon from '@mui/icons-material/Refresh';
import { setIsRefresh } from '../../store/reducers/heaterSlice';

interface Iprops {
    openMenuClick: any;
}
const HeaderBar: React.FC<Iprops> = ({ openMenuClick }) => {
    const userName = useSelector((state: any) => state.user.userName);
    const userImageLink = useSelector((state: any) => state.user.userImageLink);
    const machineId = useSelector((state: any) => state.heater.machine_id);
    const sensorTime = useSelector((state: any) => state.heater.sensor_time);
    const matches = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const { mode, setMode } = useColorScheme();
    const [updateSettings, { data: updateSettingsData, loading, error }] = useMutation(UPDATE_SETTINGS_MUTATION);
    const { t } = useTranslation();
    const location = useLocation();
    const [headerContent, setHeaderContent] = useState<any>(null);
    const [currentPath, setLocationPath] = useState<string>('');
    const [profileImageUrl, setProfileImageUrl]: any = useState(null);
    const dispatch = useDispatch();
    const { isLoggedIn } = useSelector((state: RootState) => state.user);

    const fetchUserDetails = async () => {
        try {
            const { data } = await client.query({ query: GET_USERS });
            const userId = data?.getUser?.id;
            const userName = `${data?.getUser?.first_name || ''} ${data?.getUser?.last_name || ''}`.trim();
            const userImageUrl = `${data?.getUser?.profile_image || ''}`;

            if (userName) {
                dispatch(setUserName(userName));
            }
            if (userImageUrl) {
                dispatch(setUserImageLink(userImageUrl));
            }

            if (userId) {
                dispatch(setUserId(userId));
            } else {
                console.warn('User ID not found in the response');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            // dispatch(
            //     openToast({
            //         show: true,
            //         message: t('error_fetching_user_data_message'),
            //         severity: 'error',
            //         isAlertOpen: true,
            //     }),
            // );
        }
    };
    const fetchUserThemeDetails = async () => {
        try {
            const { data: userSettingsData } = await client.query({ query: GET_USER_SYSTEM_SETTINGS });
            let sysMode = userSettingsData?.getUserSettings?.theme;
            switch (sysMode) {
                case userEnums.Theme.auto:
                    sysMode = 'system';
                    break;
                case userEnums.Theme.dark:
                    sysMode = 'dark';
                    break;
                case userEnums.Theme.light:
                    sysMode = 'light';
                    break;
                default:
            }
            setMode(sysMode);
        } catch (error) {
            console.error('Error fetching user theme data:', error);
        }
    };

    React.useEffect(() => {
        if (isLoggedIn) {
            fetchUserDetails();
            fetchUserThemeDetails();
        }
    }, [isLoggedIn]);

    const handleThemeChange = async (event: any, newMode: any) => {
        if (newMode !== null) {
            try {
                let updatedMode = newMode;
                switch (newMode) {
                    case 'system':
                        updatedMode = userEnums.Theme.auto;
                        break;
                    case 'dark':
                        updatedMode = userEnums.Theme.dark;
                        break;
                    case 'light':
                        updatedMode = userEnums.Theme.light;
                        break;
                }
                const input = {
                    theme: updatedMode,
                };
                const response = await updateSettings({ variables: { input } });
                setMode(updatedMode === userEnums.Theme.dark ? 'dark' : 'light');
                console.warn('Theme settings updated:', response.data.updateSettings);
            } catch (err) {
                console.error('Error updating settings:', err);
            }
        }
    };
    const miniAnalytics = (icon: any, header: string, headerName: string) => {
        return (
            <Stack direction="row" alignItems="center" spacing={{ xs: 1, lg: 2 }}>
                {icon}
                <Box>
                    <Typography variant="h6" color="info.main">
                        {header}
                    </Typography>
                    <Typography variant="h5" fontWeight={700}>
                        {headerName}
                    </Typography>
                </Box>
            </Stack>
        );
    };
    const setUserProfileImage = () => {
        if (userImageLink) {
            const loadImage = async () => {
                const url = await fetchImageUrl();
                if (url) {
                    setProfileImageUrl(url);
                }
            };
            loadImage();
        }
    };
    const fetchImageUrl = () => {
        return getUrl({
            path: userImageLink, // Use path instead of key
            options: {
                expiresIn: 3600,
            },
        })
            .then(result => {
                if (result && result.url) {
                    console.log('Resolved result:', result.url);
                    return result.url;
                }
                console.error('No URL found in the result:', result);
                return null;
            })
            .catch(err => {
                console.error('Error fetching image:', err);
                return null;
            });
    };

    const handleRefresh = () => {
        dispatch(setIsRefresh(true));
    }
    const getHeaderContext = (path: string) => {
        switch (path) {
            case NavigationConfig.Home:
                return (
                    <Box flexDirection={'row'} display={'flex'} gap={2}>
                        <Box >
                            <Typography variant="h4">{t('dashboard')}</Typography>
                            <Typography variant="h6" display="flex" alignItems="center" color="info">
                                {' '}
                                {machineId}
                            </Typography>
                        </Box>
                        <Box onClick={handleRefresh} pt={0.6} sx={{ cursor: 'pointer' }}>
                            <RefreshIcon />
                        </Box>
                    </Box>
                );
            case NavigationConfig.ProfileSetting:
                return (
                    <Box>
                        <Typography variant="h4">{t('profile_settings')}</Typography>
                        <Typography variant="h6" display="flex" alignItems="center" color="info">
                            {' '}
                        </Typography>
                    </Box>
                );
            default:
                return <></>;
        }
    };
    React.useEffect(() => {
        const newTitle = getHeaderContext(location.pathname);
        setHeaderContent(newTitle);
        setLocationPath(location.pathname);
        setUserProfileImage();
    }, [location.pathname, userImageLink, machineId]);


    return (
        <>
            <HeaderStyles py={2} direction="row" justifyContent="space-between" alignItems="center">
                {matches && (
                    <IconButton onClick={openMenuClick}>
                        <MenuOutlinedIcon />
                    </IconButton>
                )}
                <Box display={{ xs: 'none', sm: 'block' }}>
                    {currentPath === NavigationConfig.Home && (
                        <>
                            <Typography variant="h4">
                                {t('greetings')}, {userName}!
                            </Typography>
                            <Typography variant="h6">{t('quote1')}</Typography>
                        </>
                    )}
                </Box>
                <Stack direction="row" spacing={1.5} alignItems="center">
                    <ToggleButtonGroup value={mode} exclusive onChange={handleThemeChange} aria-label="text alignment">
                        <ToggleButton value="light" aria-label="light theme">
                            <LightModeOutlinedIcon />
                        </ToggleButton>
                        <ToggleButton value="dark" aria-label="dark theme">
                            <DarkModeOutlinedIcon />
                        </ToggleButton>
                        <ToggleButton value="system" aria-label="system theme">
                            <ContrastOutlinedIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <IconButton>
                        <NotificationsNoneOutlinedIcon />
                    </IconButton>
                    <Avatar sizes="small" sx={{ display: { xs: 'none', sm: 'flex' } }} src={profileImageUrl}>
                        {getInitials(userName)}
                    </Avatar>
                </Stack>
            </HeaderStyles>
            <StyleWrapper>
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
                    {headerContent}
                    <Stack direction="row" spacing={{ xs: 1, lg: 3 }} justifyContent="space-between">
                        {miniAnalytics(
                            <ElectricalServicesOutlinedIcon sx={{ color: isLessThan15Seconds(sensorTime) ? colors.lightBlue : colors.lightRed }} />,
                            t('power'),
                            isLessThan15Seconds(sensorTime) ? t('on') : t('off'),
                        )}
                        {miniAnalytics(<WifiOutlinedIcon sx={{ color: isLessThan15Seconds(sensorTime) ? colors.lightBlue : colors.lightRed }} />, t('internet'), isLessThan15Seconds(sensorTime) ? t('on') : t('off'))}
                    </Stack>
                </Stack>
            </StyleWrapper>
        </>
    );
};
export { HeaderBar };
