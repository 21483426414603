import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { Loader, theme, Toast } from '@watter/shared-components';
import '@watter/shared-components/src/styles';
import { Amplify } from 'aws-amplify';
import { useSelector, useDispatch } from 'react-redux';
import { LandingPage } from './pages/landing-page';

import { RootState } from './store';
import { resetToast } from './store/reducers/toastSlice';
import './translations/i18n';
import { updateLoginStatus } from './store/reducers/userSlice';
import { hideLoader, setSession, showLoader } from './store/reducers/appSlice';
import SocialLoginAuthCallback from './hooks/social-login-callback';
import { environment } from './environments/environment';

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolClientId: environment.USER_POOL_CLIENT_ID || '',
            userPoolId: environment.USER_POOL_ID || '',
            identityPoolId: environment.IDENTITY_POOL_ID || '',
            loginWith: {
                oauth: {
                    domain: environment.OAUTH_DOMAIN || '',
                    scopes: ['email', 'openid', 'profile'],
                    redirectSignIn: (environment.REDIRECT_SIGN_IN_URLS || '')
                        .split(',') // Split the string into an array
                        .map(url => url.trim()) // Remove extra spaces
                        .filter(Boolean), // Remove empty strings
                    redirectSignOut: [environment.REDIRECT_SIGN_OUT_URLS || ''],
                    responseType: 'code', // Use 'token' for implicit flow
                },
            },
        },
    },
    Storage: {
        S3: {
            bucket: environment.S3_BUCKET,
            region: environment.APP_REGION,
        },
    },
});
const App: React.FC = () => {
    const showToast = useSelector((state: RootState) => state.toast);
    const dispatch = useDispatch();
    SocialLoginAuthCallback();
    React.useEffect(() => {
        const unsubscribe = Hub.listen('auth', ({ payload }) => {
            switch (payload.event) {
                case 'signInWithRedirect':
                    getUser();
                    break;
                default:
            }
        });
        return unsubscribe;
    }, []);
    const fetchCurrentSession = async () => {
        try {
            dispatch(showLoader());
            const session = await fetchAuthSession({ forceRefresh: true });
            if (session) {
                dispatch(
                    setSession({
                        token: session?.tokens?.idToken?.toString(),
                        payload: session?.tokens?.idToken?.payload,
                    }),
                );
                dispatch(updateLoginStatus(true));
            }
        } catch (err) {
            console.error('err==>', err);
            dispatch(updateLoginStatus(false));
        } finally {
            dispatch(hideLoader());
        }
    };
    const getUser = async () => {
        try {
            const currentUser = await getCurrentUser();
            if (currentUser?.userId || currentUser?.username) {
                await fetchCurrentSession();
            }
        } catch (err: any) {
            console.error(err, 'in catch of getUser()');
        }
    };
    const closeToast = () => {
        dispatch(resetToast({ show: false, message: null, isAlertOpen: false }));
    };
    const { activeHTTPRequests } = useSelector((state: any) => state.app);
    return (
        <div className="app">
            {activeHTTPRequests >= 1 && <Loader />}
            <Toast
                open={showToast?.show}
                message={showToast?.message}
                severity={showToast?.severity}
                handleClose={closeToast}
            />
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <LandingPage />
            </ThemeProvider>
        </div>
    );
};

export { App };
