import { Box, Stack, Typography, Grid2, FormHelperText, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
    appType,
    ButtonComponent,
    CognitoCallbacks,
    CognitoErrorCodes,
    TextFieldComponent,
} from '@watter/shared-components';
import { resetPassword } from 'aws-amplify/auth/cognito';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, Form, ErrorMessage } from 'formik';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { LoginUi } from './login-style';
import { openToast } from '../../store/reducers/toastSlice';
import { hideLoader, showLoader } from '../../store/reducers/appSlice';
import { ForgotPasswordIcon } from '../../components/icons/forgot-password-icon';
import { ForgotPasswordValidationSchema } from './forgot-password-validationSchema';
import { INavigationConfig, NavigationConfig } from '../../utils/navigation-constants';

export function ForgotPassword() {
    const navConfig: INavigationConfig = NavigationConfig;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleSubmit = async (values: { email: string }) => {
        try {
            dispatch(showLoader());
            const output: any = await resetPassword({
                username: values.email,
                options: {
                    clientMetadata: {
                        application_type: `${appType.Customer}`,
                    },
                },
            });
            const { nextStep } = output;
            switch (nextStep?.resetPasswordStep) {
                case CognitoCallbacks.CONFIRM_RESET_PASSWORD_WITH_CODE:
                    navigate(navConfig.PasswordSetup, {
                        state: {
                            username: values.email,
                            maskedNumber: nextStep?.codeDeliveryDetails?.destination,
                            type: CognitoCallbacks.CONFIRM_RESET_PASSWORD_WITH_CODE,
                        },
                    });
                    break;

                case 'DONE':
                    console.log('Successfully reset password.');
                    dispatch(
                        openToast({
                            show: true,
                            message: t('password_reset_success'),
                            severity: 'success',
                            isAlertOpen: true,
                        }),
                    );
                    break;

                default:
                    throw new Error('Unexpected next step in reset password flow.');
            }
        } catch (err: any) {
            console.error(err, 'Error resetting password');

            // Handle error cases and show appropriate messages
            switch (err?.name) {
                case CognitoErrorCodes.NOT_AUTHORIZED_EXCEPTION:
                    dispatch(
                        openToast({
                            show: true,
                            message: t('not_authorize_message'),
                            severity: 'error',
                            isAlertOpen: true,
                        }),
                    );
                    break;

                case CognitoErrorCodes.RESET_PASSWORD_EXCEPTION:
                    dispatch(
                        openToast({
                            show: true,
                            message: t('reset_password_exception_message'),
                            severity: 'error',
                            isAlertOpen: true,
                        }),
                    );
                    break;

                case CognitoErrorCodes.USER_NOT_FOUND_EXCEPTION:
                    dispatch(
                        openToast({
                            show: true,
                            message: t('user_not_exists_error'),
                            severity: 'error',
                            isAlertOpen: true,
                        }),
                    );
                    break;
                case CognitoErrorCodes.USER_HAS_NO_ACCESS_EXCEPTION:
                    dispatch(
                        openToast({
                            show: true,
                            message: t('user_has_no_access_error'),
                            severity: 'error',
                            isAlertOpen: true,
                        }),
                    );
                    break;

                default:
                    dispatch(
                        openToast({
                            show: true,
                            message: err?.message || 'An unexpected error occurred.',
                            severity: 'error',
                            isAlertOpen: true,
                        }),
                    );
            }
        } finally {
            dispatch(hideLoader());
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <LoginUi container spacing={{ sm: 0, lg: 2 }}>
            <Grid2
                className="imgContainer"
                size={{ sm: 12, lg: 8 }}
                display={{ xs: 'none', lg: 'flex' }}
                alignItems="center"
            >
                <Box />
            </Grid2>
            <Grid2 size={{ xs: 12, lg: 4 }} className="feildsLabels" minWidth={{ xs: '100%', lg: 'auto' }}>
                <IconButton className="backBtn" onClick={handleBack}>
                    <KeyboardBackspaceIcon />
                </IconButton>
                <Box height="100%">
                    <Box
                        pt={3}
                        px={5}
                        pb={2}
                        height="100%"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                    >
                        <Box textAlign="center" className="logo forgotIcon">
                            <ForgotPasswordIcon />
                        </Box>
                        <Typography variant="h1" py={2} textAlign="center" data-testid="forgot-password-title">
                            {t('forgot_password_text')}
                        </Typography>
                        <Typography variant="h6" py={2} textAlign="center">
                            {t('forgot_password_message')}
                        </Typography>
                        <Formik
                            initialValues={{ email: '' }}
                            validationSchema={ForgotPasswordValidationSchema()}
                            onSubmit={handleSubmit}
                        >
                            {({ values, setFieldValue }: any) => (
                                <Form>
                                    <Stack mb={1.25} className="cstInput">
                                        <TextFieldComponent
                                            name="email"
                                            type="email"
                                            placeholder="Email"
                                            value={values.email}
                                            onChange={(e: any) => setFieldValue('email', e.target.value.trim())}
                                            // data-testid="email-input"
                                        />

                                        <FormHelperText error>
                                            <ErrorMessage name="email" />
                                        </FormHelperText>
                                    </Stack>
                                    <Stack rowGap={2} className="actionItems">
                                        <ButtonComponent
                                            size="medium"
                                            variant="contained"
                                            fullWidth
                                            buttonName="Submit"
                                            type="submit"
                                            className="cstBtn"
                                            data-testid="forgot-password-submit"
                                        />
                                        <ButtonComponent
                                            size="medium"
                                            variant="contained"
                                            fullWidth
                                            buttonName={t('back_to_login_btn')}
                                            className="cstBtn"
                                            onClick={() => navigate(NavigationConfig.Login)}
                                        />
                                    </Stack>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Grid2>
        </LoginUi>
    );
}
