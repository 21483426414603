import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Stack } from '@mui/material';
import { Routing } from '../../routes';
import { RootState } from '../../store';
import { SideMenu } from '../../components/side-menu/side-menu';
import { LandingPageStyle } from './landing-page-style';
import { PostAuthenticatedRoutes } from '../../routes/post-authenticated-routes';
import { HeaderBar } from '../../components/header/header-bar';

const LandingPage: React.FC = () => {
    const { isLoggedIn } = useSelector((state: RootState) => state.user);
    const [open, setOpen] = React.useState(false);
    return (
        <Stack>
            {isLoggedIn ? (
                <LandingPageStyle>
                    <SideMenu menuOpen={open} menuClose={() => setOpen(false)} />
                    <Box className="body-content">
                        <HeaderBar openMenuClick={() => setOpen(true)} />
                        <PostAuthenticatedRoutes />
                    </Box>
                </LandingPageStyle>
            ) : (
                <Routing />
            )}
        </Stack>
    );
};

export { LandingPage };
