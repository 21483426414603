import { createSlice } from '@reduxjs/toolkit';
import { revertAll } from '../actions';

export interface IAppState {
    activeHTTPRequests: number;
    isDarkTheme: boolean;
    session: { [key: string]: any };
}

const initialState: IAppState = {
    activeHTTPRequests: 0,
    isDarkTheme: false,
    session: {},
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        showLoader: state => {
            state.activeHTTPRequests += 1;
        },
        hideLoader: state => {
            state.activeHTTPRequests = state.activeHTTPRequests > 1 ? state.activeHTTPRequests - 1 : 0;
        },
        toggleIsDarkTheme: state => {
            state.isDarkTheme = !state.isDarkTheme;
        },
        setSession: (state, action) => {
            state.session = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(revertAll, () => initialState);
    },
});

export const { showLoader, hideLoader, toggleIsDarkTheme, setSession } = appSlice.actions;
export const appReducer = appSlice.reducer;
