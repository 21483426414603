import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { setupListeners } from '@reduxjs/toolkit/query';
import { logger } from 'redux-logger';
import { rootReducer } from './reducers';

const persistConfig = {
    key: 'root',
    storage,
    timeout: 0,
    blacklist: ['Toast'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(logger),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);

// Expose store for Cypress testing
if (window.Cypress) {
    window.store = store;
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
