export function ForgotPasswordIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="82" height="101" viewBox="0 0 82 101" fill="none">
            <path
                d="M70.2241 34H40.673H11.7759C5.82452 34 0.953874 38.8262 1.20552 44.7722C2.3217 71.1455 8.86194 100 41.109 100C73.3511 100 79.7263 71.1221 80.8029 44.7724C81.0459 38.826 76.1754 34 70.2241 34Z"
                strokeWidth="2"
            />
            <path
                d="M19 34V22.5517C19 10.649 28.649 1 40.5517 1H41.4483C53.351 1 63 10.649 63 22.5517V34"
                strokeWidth="2"
            />
            <path d="M48 60.3636C48 62.4204 47.1984 64.2803 45.9058 65.6163C45.4193 66.1192 45.1042 66.787 45.1738 67.4832L45.9053 74.8011C46.023 75.9784 45.0984 77 43.9152 77H38.1743C36.9995 77 36.0777 75.9922 36.1823 74.822L36.8392 67.4706C36.9008 66.7811 36.586 66.1228 36.1038 65.6262C34.8056 64.2895 34 62.4255 34 60.3636C34 56.2968 37.134 53 41 53C44.866 53 48 56.2968 48 60.3636Z" />
        </svg>
    );
}
