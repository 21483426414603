enum ApplicationType {
    customer = 1,
    installer = 2,
    admin = 3,
}

enum AddressType {
    billing = 1,
    shipping = 2,
}

enum Status {
    inactive = 0,
    active = 1,
    deleted = 2,
}

enum Theme {
    auto = 0,
    light = 1,
    dark = 2,
}

export { ApplicationType, AddressType, Theme, Status };
