enum ConnectionType {
    none = 0,
    wifi = 1,
    wired = 2,
}

enum OperationMode {
    none = 0,
    gpu = 1,
    hybrid = 2,
    conventional = 3,
}

enum Power {
    off = 0,
    on = 1,
}

enum Internet {
    off = 0,
    on = 1,
}

export { ConnectionType, OperationMode, Power, Internet };
