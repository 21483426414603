enum NotificationsType {
    profile = 1,
    dashborard = 2,
    users = 3
}

enum ReadStatus {
    true = 1,
    false = 0,
}

enum Status {
    deleted = 0,
    active = 1,
}

export { NotificationsType,  ReadStatus, Status};
