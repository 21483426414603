import { alpha, Box, Stack, styled, useTheme } from '@mui/material';
import React from 'react';
import { colors } from '../theme';

const DarkLoader = require('../../../assets/images/darkLoading.gif');
const LightLoader = require('../../../assets/images/lightLoading.gif');

export const LoaderUi = styled(Stack)(({ theme }) => ({
    position: 'fixed',
    inset: 0,
    background: alpha(colors.darkBgButton, 0.5),
    height: '100dvh',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
    '& .MuiBox-root': {
        backgroundImage: theme.palette.mode === 'dark' ? `url(${DarkLoader})` : `url(${LightLoader})`,
        width: theme.spacing(18.75),
        height: theme.spacing(18.75),
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
}));
export function Loader() {
    return (
        <LoaderUi>
            <Box />
        </LoaderUi>
    );
}
