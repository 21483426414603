import React from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { TextFieldWrapper } from './TextFieldStyles';

interface IProps {
    id?: any;
    onChange?: any;
    variant?: 'outlined' | 'filled' | 'standard';
    disabled?: boolean;
    required?: boolean;
    readonly?: boolean;
    type?: string;
    placeholder?: string;
    error?: boolean;
    name?: string;
    value?: string;
    size?: 'medium' | 'small';
    isAutoFocus?: boolean;
    inputProps?: any;
    onBlur?: any;
    maxLength?: any;
    className?: any;
}
export const TextFieldComponent: React.FC<IProps> = ({
    onChange,
    variant,
    disabled,
    required,
    id,
    type,
    placeholder,
    error,
    name,
    value,
    size,
    isAutoFocus = false,
    onBlur,
    maxLength,
    className,
}) => {
    const [showPassword, setShowPassword] = React.useState(false);

    return (
        <TextFieldWrapper>
            <TextField
                id={id}
                variant={variant}
                onChange={onChange}
                disabled={disabled}
                required={required}
                placeholder={placeholder}
                type={type}
                autoComplete="off"
                size={size}
                error={error}
                name={name}
                value={value}
                autoFocus={isAutoFocus}
                InputProps={{
                    ...(type === 'password' && {
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(prev => !prev)} edge="end">
                                    {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </IconButton>
                            </InputAdornment>
                        ),
                        type: showPassword ? 'text' : 'password',
                    }),
                }}
                slotProps={{ htmlInput: { maxLength } }}
                onBlur={onBlur}
                className={className}
            />
        </TextFieldWrapper>
    );
};
