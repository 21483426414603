import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ModalUI } from './modal-styles';

interface Iprops {
    header?: any;
    children?: any;
    cancelBtnName?: string;
    AcceptBtnName?: string;
    modalOpen?: any;
    closePopup: any;
    acceptBtn: any;
    className?: any;
}

export const CommonModal: React.FC<Iprops> = ({
    header,
    children,
    cancelBtnName,
    AcceptBtnName,
    modalOpen,
    closePopup,
    acceptBtn,
    className,
}) => {
    return (
        <ModalUI
            open={modalOpen}
            onClose={closePopup}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={className}
        >
            <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closePopup}>{cancelBtnName}</Button>
                <Button onClick={acceptBtn} autoFocus>
                    {AcceptBtnName}
                </Button>
            </DialogActions>
        </ModalUI>
    );
};
