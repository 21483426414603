import { Alert, alpha, Snackbar, styled } from '@mui/material';
import React from 'react';
import { colors } from '../theme';

interface IContainerProps {
    open: boolean;
    message?: string;
    severity?: any;
    handleClose: (value: boolean) => void;
}
export const ToastUI = styled(Snackbar)(({ theme }) => ({
    minWidth: '10%',
    maxWidth: '31%',
    [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
    },
    '& .MuiPaper-root': {
        borderRadius: '30px',
        '& .MuiAlert-action': {
            padding: '4px 6px 0 8px',
        },
        '&.MuiAlert-colorError': {
            background: alpha(colors.toastError, 0.8),
        },
    },
}));
export function Toast(props: IContainerProps) {
    const { open, message, severity, handleClose } = props;
    React.useEffect(() => {
        if (!open) {
            handleClose(false); // Close toast when not open
        }
    }, [open, handleClose]);
    return (
        <ToastUI
            open={open}
            onClose={() => handleClose(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={30000}
        >
            <Alert
                data-testid="toast-message"
                variant="filled"
                severity={severity}
                onClose={() => handleClose(false)}
                sx={{ width: '100%' }}
                icon={false}
            >
                {message}
            </Alert>
        </ToastUI>
    );
}
