import * as Yup from 'yup';

export const ProfileSettingValidationSchema = (mdoValidations: any) =>
    Yup.object().shape({
        firstName: Yup.string()
            .min(3, 'First Name should be atleast 3 characters')
            .max(30, 'First Name should be a max of 30 characters')
            .trim('Starting spaces are not allowed')
            .required('First Name required'),
        lastName: Yup.string()
            .min(1, 'Last Name should be atleast 1 character')
            .max(30, 'Last Name should be a max of 30 characters')
            .trim('Starting spaces are not allowed')
            .required('Last Name required'),
        email: Yup.string()
            .email('Please enter a vaild email')
            .trim('Starting spaces are not allowed')
            .required('Email required'),
        street: Yup.string()
            .min(3, 'Street should be atleast 3 characters')
            .max(30, 'Street should be a max of 30 characters')
            .trim('Starting spaces are not allowed')
            .required('Street Name required'),
        city: Yup.string()
            .min(3, 'City Name should be atleast 3 characters')
            .max(30, 'City Name should be a max of 30 characters')
            .trim('Starting spaces are not allowed')
            .required('City Name required'),
        state: Yup.string().required('Select State'),
        zipCode: Yup.string()
            .min(5, 'ZIP code must be at least 5 digits long.')
            .max(10, 'ZIP code cannot exceed 10 digits')
            .matches(/^([1-9][0-9]{5}|\d{5}(-\d{4})?)$/, 'Please enter a valid zip code')
            .required('Zip Code is required'),
        phoneNumber: Yup.string().required('Mobile Number is required'),
    });
