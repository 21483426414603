enum InstallationStatus {
    created = 1,
    initiated = 2,
    inprogress = 3,
    inactive = 4,
    completed = 5,
}

enum UpdatedBy {
    customer = 1,
    installer = 2,
    admin = 3,
}

export { InstallationStatus, UpdatedBy };
