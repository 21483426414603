import React from 'react';
import ReactDOM from 'react-dom/client';
import '@watter/shared-components/src/styles';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ApolloProvider } from '@apollo/client';
import { store, persistor } from './store';
import { App } from './App';

import { reportWebVitals } from './reportWebVitals';
import { client } from './service/apolloClient';

// Make Redux store globally available for Cypress
if (process.env.NODE_ENV === 'development') {
    window.store = store;
}

export const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ApolloProvider client={client}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </ApolloProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
