import { useTranslation } from 'react-i18next';
import {
    Autocomplete,
    Box,
    FormControl,
    FormHelperText,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material';
import {
    ButtonComponent,
    CommonModal,
    TextFieldComponent,
    OtpInput,
    CognitoErrorCodes,
    CognitoCallbacks,
    isNumber,
    GET_USERS,
    UPDATE_USER,
    colors,
} from '@watter/shared-components';
import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid2';
import { ErrorMessage, Form, Formik } from 'formik';
import { GetCountries, GetState } from 'react-country-state-city';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { uploadData, getUrl } from 'aws-amplify/storage';
import { userEnums } from '@watter/shared-types';
import { confirmUserAttribute, ConfirmUserAttributeInput, updateUserAttributes } from '@aws-amplify/auth';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { nanoid } from 'nanoid';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import { setUserName, setUserImageLink } from '../../store/reducers/userSlice';
import { hideLoader, showLoader } from '../../store/reducers/appSlice';
import { openToast } from '../../store/reducers/toastSlice';
import { MainWrapper } from '../common-styles';
import 'react-image-crop/dist/ReactCrop.css';
import { client } from '../../service/apolloClient';
import { ProfileSettingValidationSchema } from './profile-setting-validationschema';
import ProfileSetPassword from './change-password';

const darkProfile = require('../../assets/images/emptyProfile.svg').default;
const lightProfile = require('../../assets/images/emptyProfile.svg').default;

const profile = ({ theme }: { theme: any }) => (theme.palette.mode === 'dark' ? darkProfile : lightProfile);
interface IFormObj {
    firstName: string;
    lastName: string;
    email: string;
    mobile_number: string;
    street: string;
    city: string;
    zipCode: string;
    profileImageURI: any;
    state?: string | null;
    phoneNumber: string;
}
interface IUser {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    profile_image: string;
    user_addresses: {
        id: number;
        street: string;
        city: string;
        zip_code: string;
        country: string;
        state: string;
    }[];
}

export function ProfileSetting() {
    const theme = useTheme();
    const { t } = useTranslation();
    const matches = useMediaQuery(theme => theme.breakpoints.down('md'));
    const [editProfile, setEditProfile] = useState(false);
    const [open, setOpen] = useState(false);
    const [countriesList, setCountriesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [selectedCountryId, setCountryId] = useState(0);
    const [selectedCountryPhoneCode, setCountryPhoneCode] = useState('');
    const [selectedStateId, setStateId] = useState(0);
    const [userAddressId, setUserAddressId] = useState(0);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [oldPhoneNumber, setOldPhoneNumber] = useState('');
    const [state, setStateName] = useState('');
    const [country, setCountryName] = useState('');
    const [openVerifyMobilePopUp, setOpenVerifyMobilePopUp]: any = useState();
    const [isValidOtp, setIsValidOtp] = useState<boolean>(false);
    const [isResendEnabled, setIsResendEnabled] = useState<boolean>(false);
    const [otpValue, setOtpValue] = useState<string>('');
    const dispatch = useDispatch();
    const timerValueInSec = 3 * 60;
    const [timer, setTimer] = useState<number>(timerValueInSec);
    const [userCountryCode, setUserCountryCode] = useState('');
    const [userStateCode, setUserStateCode] = useState('');
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
    const [userId, sertUserIdValue] = useState(0);
    const [formErrors, setFormErrors]: any = useState(null);
    const [profileImageUrl, setProfileImageUrl]: any = useState(null);
    const [initialForm, setInitialForm] = useState<IFormObj>({
        firstName: '',
        lastName: '',
        email: '',
        mobile_number: '',
        street: '',
        city: '',
        zipCode: '',
        profileImageURI: null,
        state: '',
        phoneNumber: '',
    });

    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [crop, setCrop] = useState<Crop>({
        unit: 'px',
        width: 50,
        height: 50,
        x: 0,
        y: 0,
    });
    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
    const imgRef = useRef<HTMLInputElement>(null);
    const [openModal, setOpenModal] = useState(false);
    const [initialCrop, setInitialCrop] = useState<Crop | null>(null);
    const selectImage = (file: File) => {
        if (file && file.type.startsWith('image/')) {
            setImageSrc(URL.createObjectURL(file));
            setCroppedImageUrl(null);
        } else {
            dispatch(
                openToast({
                    show: true,
                    message: t('please_select_valid_img'),
                    severity: 'error',
                    isAlertOpen: true,
                }),
            );
        }
    };

    const cropImageNow = async (setFieldValue: (field: string, value: any) => void) => {
        if (!crop.width || !crop.height || !image) {
            dispatch(
                openToast({
                    show: true,
                    message: t('please_select_valid_crop'),
                    severity: 'error',
                    isAlertOpen: true,
                }),
            );
            return;
        }

        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        // Ensure the cropped area is based on the image’s original resolution
        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;

        const ctx = canvas.getContext('2d');
        if (!ctx) {
            console.error('Unable to get canvas context.');
            return;
        }

        // Disable image smoothing for sharpness
        ctx.imageSmoothingEnabled = false;
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x! * scaleX,
            crop.y! * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            canvas.width,
            canvas.height,
        );

        canvas.toBlob(
            async blob => {
                if (blob) {
                    const file = new File([blob], 'cropped-image.jpg', {
                        type: 'image/jpeg',
                    });
                    setCroppedImageUrl(URL.createObjectURL(file));
                    setInitialForm({ ...initialForm, profileImageURI: file });
                }
            },
            'image/jpeg',
            1.0, // Maximum quality
        );
    };

    const fetchImageUrl = () => {
        return getUrl({
            path: initialForm.profileImageURI, // Use path instead of key
            options: {
                expiresIn: 3600,
            },
        })
            .then(result => {
                if (result && result.url) {
                    console.warn('Resolved result:', result.url);
                    return result.url;
                }
                console.error('No URL found in the result:', result);
                return null;
            })
            .catch(err => {
                console.error('Error fetching image:', err);
                return null;
            });
    };
    useEffect(() => {
        if (initialForm.profileImageURI) {
            const loadImage = async () => {
                const url = await fetchImageUrl();
                if (url) {
                    setProfileImageUrl(url);
                }
            };
            loadImage();
        }
    }, [initialForm.profileImageURI]);

    const [userInfo, setUserInfo] = useState<IUser>({
        id: -1,
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        profile_image: '',
        user_addresses: [
            {
                id: -1,
                street: '',
                city: '',
                zip_code: '',
                country: '',
                state: '',
            },
        ],
    });
    React.useEffect(() => {
        GetCountries().then((result: any) => {
            const filteredCountries: any = [];
            result.forEach((country: any) => {
                if (country.name === 'India' || country.name === 'United States') {
                    country.phone_code = `${`+${country.phone_code}`}`;
                    filteredCountries.push(country);
                }
                setCountriesList(filteredCountries);
            });
        });
    }, []);
    React.useEffect(() => {
        const data: any = countriesList.filter((i: any) => i.iso2 === userCountryCode);
        setCountryId(data[0]?.id);
        setCountryName(data[0]?.name);
        setCountryPhoneCode(data[0]?.phone_code);
        setStateList([]);
        setStateId(0);
        setInitialForm({ ...initialForm, state: '' });
        GetState(data[0]?.id).then((result: any) => {
            setStateList(result);
        });
    }, [userCountryCode, countriesList]);

    React.useEffect(() => {
        const data: any = stateList.filter((i: any) => i.state_code === userStateCode);
        setStateId(data[0]?.id);
        setInitialForm({ ...initialForm, state: data[0]?.name });
        setStateName(data[0]?.name);
    }, [stateList, userStateCode]);

    React.useEffect(() => {
        const number = phoneNumber.startsWith(selectedCountryPhoneCode)
            ? phoneNumber.slice(selectedCountryPhoneCode?.length)
            : phoneNumber;
        setPhoneNumber(number);
    }, [selectedCountryPhoneCode, phoneNumber]);

    const validatePhoneNumber = (phoneNumber: string) => {
        let isValid = false;
        if (isNumber(phoneNumber) && phoneNumber?.length === 10) {
            isValid = true;
        }
        return isValid;
    };

    React.useEffect(() => {
        if (phoneNumber && phoneNumber !== oldPhoneNumber) {
            if (validatePhoneNumber(phoneNumber)) {
                setIsValidPhoneNumber(true);
            } else {
                setIsValidPhoneNumber(false);
            }
        } else {
            setIsValidPhoneNumber(true);
        }
    }, [phoneNumber]);

    React.useEffect(() => {
        let interval: NodeJS.Timeout | undefined;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        } else {
            setIsResendEnabled(true);
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [timer]);

    const fetchUserData = async () => {
        try {
            const { data } = await client.query({
                query: GET_USERS,
                fetchPolicy: 'network-only',
            });
            if (data && data.getUser) {
                const user: IUser = data.getUser;
                const address = user.user_addresses?.[0] || {};
                setUserAddressId(address.id);
                setOldPhoneNumber(user.phone_number);
                setPhoneNumber(user.phone_number.trim());
                setUserCountryCode(address.country);
                setUserStateCode(address.state);
                sertUserIdValue(user.id);
                setInitialForm({
                    firstName: user.first_name || '',
                    lastName: user.last_name || '',
                    email: user.email || '',
                    mobile_number: user.phone_number || '',
                    street: address.street || '',
                    city: address.city || '',
                    zipCode: address.zip_code || '',
                    profileImageURI: user.profile_image || null,
                    state: address.state || '',
                    phoneNumber: user.phone_number.trim(),
                });
                setUserInfo(prevUserInfo => ({
                    ...prevUserInfo,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: user.email,
                    phone_number: user.phone_number.trim(),
                    profile_image: user.profile_image,
                    user_addresses: [
                        {
                            id: address.id,
                            street: address.street,
                            city: address.city,
                            zip_code: address.zip_code,
                            country: address.country,
                            state: address.state,
                        },
                    ],
                }));
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const uploadImage = async (file: File): Promise<string> => {
        try {
            const imageName = nanoid();
            const s3Path = `${userId}/${imageName}.jpg`; // Path for the S3 object
            const result = await uploadData({
                path: s3Path,
                data: file,
                options: {
                    contentType: 'image/jpg', // Ensure correct content type
                },
            }).result;

            // If upload successful, return the file's URL from S3
            if (result?.path) {
                return result.path; // This should be the S3 URL of the uploaded image
            }
            throw new Error('Failed to upload image');
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error; // Throw error if upload fails
        }
    };

    const getCountryCode = () => {
        const country: any = countriesList.filter((i: any) => i.id === selectedCountryId);
        return country[0]?.iso2;
    };
    const getStateCode = () => {
        const state: any = stateList.filter((i: any) => i.id === selectedStateId);
        return state[0]?.state_code;
    };
    const isValidForm = () => {
        const error: any = {};
        if (!selectedCountryId) {
            error.country = t('select_country');
        }
        setFormErrors(error);
        return Object.keys(error).length === 0;
    };
    const editProfileAPI = async (formData: IFormObj) => {
        if (isValidPhoneNumber) {
            try {
                dispatch(showLoader());
                let profileImageURL: string | null = null; // Initialize as null or string

                // If profileImageURI is a File (image selected)
                if (formData.profileImageURI && formData.profileImageURI instanceof File) {
                    profileImageURL = await uploadImage(formData.profileImageURI); // Upload the image and get the URL
                } else if (formData.profileImageURI && typeof formData.profileImageURI === 'string') {
                    // If profileImageURI is a string (URL), use it directly
                    profileImageURL = formData.profileImageURI || null; // Use null if empty string
                }

                setInitialForm({
                    ...initialForm,
                    mobile_number: selectedCountryPhoneCode + phoneNumber,
                });

                const input: any = {
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                    // profile_image: profileImageURL,
                    phone_number: selectedCountryPhoneCode + phoneNumber,
                    user_addresses: {
                        address_type: userEnums.AddressType.billing,
                        city: formData.city,
                        country: getCountryCode()?.toString(),
                        id: userAddressId,
                        state: getStateCode()?.toString(),
                        street: formData.street,
                        zip_code: formData.zipCode,
                    },
                };
                if (profileImageURL) {
                    input.profile_image = profileImageURL;
                }

                const { data } = await client.mutate({
                    mutation: UPDATE_USER,
                    variables: { input },
                });

                console.warn('Profile updated:', data);
                dispatch(
                    openToast({
                        show: true,
                        message: 'Profile updated successfully!',
                        severity: 'success',
                        isAlertOpen: true,
                    }),
                );

                const updatedFirstName = data?.updateUser?.first_name || formData.firstName;
                const updatedLastName = data?.updateUser?.last_name || formData.lastName;
                const updatedUserName = `${updatedFirstName} ${updatedLastName}`.trim();

                // Dispatch to update Redux store
                dispatch(setUserName(updatedUserName));
                if (profileImageURL) {
                    dispatch(setUserImageLink(profileImageURL));
                }
                // Fetch the updated user data
                fetchUserData();
                setEditProfile(false);
            } catch (err) {
                console.error('Error saving profile:', err);
                alert('Error updating profile. Please try again.');
            } finally {
                dispatch(hideLoader());
            }
        } else {
            dispatch(
                openToast({ show: true, message: 'Enter valid phone number', severity: 'error', isAlertOpen: true }),
            );
        }
    };

    const onSubmit = async (values: IFormObj) => {
        if (isValidForm()) {
            const newMobileNumber = selectedCountryPhoneCode + phoneNumber;
            if (newMobileNumber !== oldPhoneNumber) {
                handleVerifyMobileNumber();
            } else if (editProfile) {
                await editProfileAPI(values);
            }
        }
    };

    const closeEditProfile = () => {
        setEditProfile(false);
        setInitialForm({
            ...initialForm,
            firstName: userInfo.first_name,
            lastName: userInfo.last_name,
            email: userInfo.email,
            mobile_number: userInfo.phone_number,
            street: userInfo.user_addresses[0]?.street,
            city: userInfo.user_addresses[0]?.city,
            zipCode: userInfo.user_addresses[0]?.zip_code,
            profileImageURI: userInfo.profile_image,
            state: userInfo.user_addresses[0]?.state,
            phoneNumber: userInfo.phone_number,
        });
        setPhoneNumber(userInfo.phone_number.trim());
        setUserCountryCode(userInfo.user_addresses[0]?.country ?? '');
        setUserStateCode(userInfo.user_addresses[0]?.state ?? '');
        setImageSrc(profileImageUrl);
        setCroppedImageUrl(profileImageUrl);
    };
    function ActionItems({ submitForm }: { submitForm: () => void }) {
        return (
            <Grid container
                className="actionFooter"
                spacing={2}
                pt={2}
            >
                {!editProfile && (
                    <Grid size={{ xs: 12, sm: 6, md: 7 }}>
                        <ButtonComponent
                            size="medium"
                            variant="contained"
                            fullWidth
                            buttonName={t('change_password')}
                            className="cstBtn"
                            onClick={() => setOpen(true)}
                        />
                    </Grid>
                )}
                {editProfile && (
                    <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                        <ButtonComponent
                            size="medium"
                            variant="contained"
                            fullWidth
                            buttonName={t('cancel')}
                            className="cstBtn"
                            onClick={() => closeEditProfile()}
                        />
                    </Grid>
                )}
                {editProfile && (
                    <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                        <ButtonComponent
                            size="medium"
                            variant="contained"
                            fullWidth
                            buttonName={t('save')}
                            className="cstBtn"
                            onClick={() => submitForm()}
                        />
                    </Grid>
                )}
                {!editProfile && (
                    <Grid size={{ xs: 12, sm: 6, md: 5 }}>
                        <ButtonComponent
                            size="medium"
                            variant="contained"
                            fullWidth
                            buttonName={t('edit_profile')}
                            className="cstBtn"
                            onClick={() => setEditProfile(true)}
                        />
                    </Grid>
                )}
            </Grid>
        );
    }

    useEffect(() => {
        fetchUserData();
    }, []);
    const handleCountryChange = (e: any) => {
        setCountryId(e?.id);
        GetState(e?.id).then((result: any) => {
            setStateList(result);
        });
        const data: any = countriesList.filter((i: any) => i.id === e?.id);
        setUserCountryCode(data[0]?.iso2);
        setCountryPhoneCode(data[0]?.phone_code); // to show country prefix
        // clearing address fields on country change
        initialForm.city = '';
        initialForm.street = '';
        initialForm.zipCode = '';
        setUserStateCode('');
        setPhoneNumber('');
        initialForm.phoneNumber = '';
    };
    const handleStateChange = (e: any) => {
        setStateId(e?.id);
        setInitialForm({ ...initialForm, state: e?.id });
        const data: any = stateList.filter((i: any) => i.id === e?.id);
        setUserStateCode(data[0]?.state_code);
    };
    const handleVerifyMobileNumber = async () => {
        if (isValidPhoneNumber) {
            try {
                dispatch(showLoader());
                const result: any = await updateUserAttributes({
                    userAttributes: {
                        phone_number: selectedCountryPhoneCode + phoneNumber,
                    },
                });
                console.warn(result, 'result in handleVerifyMobileNumber');
                switch (result?.phone_number?.nextStep?.updateAttributeStep) {
                    case CognitoCallbacks.DONE:
                        setOpenVerifyMobilePopUp(false);
                        editProfileAPI(initialForm);
                        break;
                    case CognitoCallbacks.CONFIRM_ATTRIBUTE_WITH_CODE:
                        dispatch(
                            openToast({
                                show: true,
                                message: t('enter_otp_received_to_new_number'),
                                severity: 'success',
                                isAlertOpen: true,
                            }),
                        );
                        setTimer(timerValueInSec);
                        setOpenVerifyMobilePopUp(true);
                        break;
                    default:
                }
            } catch (err: any) {
                console.error(err, 'err in verify mobile number API');
                switch (err?.name) {
                    case CognitoErrorCodes.INVALID_PARAM_EXCEPTION:
                        dispatch(
                            openToast({
                                show: true,
                                message: t('invalid_phone_number'),
                                severity: 'error',
                                isAlertOpen: true,
                            }),
                        );
                        break;
                    case CognitoErrorCodes.NOT_AUTHORIZED_EXCEPTION:
                        dispatch(
                            openToast({
                                show: true,
                                message: t('profile_not_authorised_err_msg'),
                                severity: 'error',
                                isAlertOpen: true,
                            }),
                        );
                        break;
                    default:
                        dispatch(
                            openToast({
                                show: true,
                                message: err?.message,
                                severity: 'error',
                                isAlertOpen: true,
                            }),
                        );
                        break;
                }
            } finally {
                dispatch(hideLoader());
            }
        } else {
            dispatch(
                openToast({ show: true, message: 'Enter valid phone number', severity: 'error', isAlertOpen: true }),
            );
        }
    };
    const handlePhoneNumberChange = (e: any) => {
        setPhoneNumber(e.target.value);
        setInitialForm({ ...initialForm, phoneNumber: e.target.value });
    };
    const handleCloseVerifyPopUp = () => {
        setOpenVerifyMobilePopUp(false);
        closeEditProfile();
    };
    const validateOtp = async ({ userAttributeKey, confirmationCode }: ConfirmUserAttributeInput) => {
        if (isValidOtp) {
            try {
                await confirmUserAttribute({ userAttributeKey, confirmationCode });
                handleVerifyMobileNumber();
            } catch (err: any) {
                switch (err?.name) {
                    case CognitoErrorCodes.CODE_MISMATCH_EXCEPTION:
                        dispatch(
                            openToast({
                                show: true,
                                message: t('otp_invalid_message'),
                                severity: 'error',
                                isAlertOpen: true,
                            }),
                        );
                        break;
                    case CognitoErrorCodes.NOT_AUTHORIZED_EXCEPTION:
                        dispatch(
                            openToast({
                                show: true,
                                message: t('otp_expired_message'),
                                severity: 'error',
                                isAlertOpen: true,
                            }),
                        );
                        break;
                    case CognitoErrorCodes.SIGN_IN_EXCEPTION:
                        dispatch(
                            openToast({
                                show: true,
                                message: t('sign_in_exception_message'),
                                severity: 'error',
                                isAlertOpen: true,
                            }),
                        );
                        break;
                    default:
                        dispatch(openToast({ show: true, message: err?.name, severity: 'error', isAlertOpen: true }));
                }
            }
        } else {
            dispatch(
                openToast({
                    show: true,
                    message: t('enter_otp_received_to_new_number'),
                    severity: 'error',
                    isAlertOpen: true,
                }),
            );
        }
    };
    const handleOtpChange = (otp: string, length: number) => {
        setOtpValue(otp);
        if (otp?.length === length) {
            setIsValidOtp(true);
        }
    };
    const resendOtp = async () => {
        setIsValidOtp(false);
        setTimer(timerValueInSec);
        setIsResendEnabled(false);
        handleVerifyMobileNumber();
    };
    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs.toString().padStart(2, '0')}`;
    };
    return (
        <MainWrapper>
            <Formik
                initialValues={initialForm}
                validationSchema={ProfileSettingValidationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ values, setFieldValue, submitForm }) => (
                    <Form style={{ gap: '2rem' }}>
                        <Grid container flexGrow={1} className="details profileDetails" spacing={2}>
                            <Grid size={{ xs: 12, sm: 6 }}>
                                <Stack>
                                    <Typography variant="subtitle1" mb={1} color="info.100">
                                        {t('first_name')}{' '}
                                        {editProfile && (
                                            <Box component="span" color={colors.astric}>
                                                *
                                            </Box>
                                        )}
                                    </Typography>
                                    <TextFieldComponent
                                        variant="outlined"
                                        disabled={!editProfile}
                                        required
                                        name="firstName"
                                        type="text"
                                        placeholder={`${t('enter_first_name')}`}
                                        onChange={(e: any) =>
                                            setInitialForm({ ...initialForm, firstName: e.target.value })
                                        }
                                        value={initialForm.firstName}
                                    />
                                    <FormHelperText error>
                                        <ErrorMessage name="firstName" />
                                    </FormHelperText>
                                </Stack>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6 }}>
                                <Stack>
                                    <Typography variant="subtitle1" mb={1} color="info.100">
                                        {t('last_name')}
                                        {editProfile && (
                                            <Box component="span" color={colors.astric}>
                                                *
                                            </Box>
                                        )}
                                    </Typography>
                                    <TextFieldComponent
                                        variant="outlined"
                                        disabled={!editProfile}
                                        required
                                        id=""
                                        error={false}
                                        size="small"
                                        isAutoFocus
                                        name="lastName"
                                        type="text"
                                        placeholder={`${t('enter_last_name')}`}
                                        onChange={(e: any) =>
                                            setInitialForm({ ...initialForm, lastName: e.target.value })
                                        }
                                        value={initialForm.lastName}
                                    />
                                    <FormHelperText error>
                                        <ErrorMessage name="lastName" />
                                    </FormHelperText>
                                </Stack>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6 }}>
                                <Stack className="cstEmail">
                                    <Typography variant="subtitle1" mb={1} color="info.100">
                                        {t('email_id')}
                                    </Typography>
                                    <TextFieldComponent
                                        variant="outlined"
                                        disabled
                                        required
                                        id=""
                                        error={false}
                                        size="small"
                                        isAutoFocus
                                        name="email"
                                        type="text"
                                        placeholder={`${t('enter_email')}`}
                                        value={initialForm.email}
                                        className={editProfile ? 'cstDisableEmail' : ''}
                                    />
                                    <FormHelperText error>
                                        <ErrorMessage name="email" />
                                    </FormHelperText>
                                </Stack>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6 }} display="flex" alignItems="flex-end">
                                <Stack width="100%">
                                    {!editProfile && (
                                        <>
                                            <Typography variant="subtitle1" mb={1} color="info.100">
                                                {t('country')}
                                            </Typography>
                                            <TextFieldComponent
                                                variant="outlined"
                                                disabled={!editProfile}
                                                required
                                                id=""
                                                error={false}
                                                size="small"
                                                isAutoFocus
                                                name="country"
                                                type="text"
                                                value={country || '-'}
                                            />
                                        </>
                                    )}

                                    {editProfile && (
                                        <>
                                            <FormControl className="cstSelectProfile cstState">
                                                {/* <InputLabel id="demo-simple-select-label">
                                                    {t('country')}{' '}
                                                    <Box component="span" color={colors.astric}>
                                                        *
                                                    </Box>
                                                </InputLabel> */}
                                                {/* <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Country"
                                                    placeholder={`${t('select_country')}`}
                                                    value={selectedCountryId || ''}
                                                    onChange={e => handleCountryChange(e)}
                                                    className="cstEditProfile"
                                                >
                                                    {countriesList.map((item: any) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select> */}
                                                <Autocomplete

                                                    options={countriesList}
                                                    getOptionLabel={(option: any) => option.name || ''}
                                                    value={
                                                        selectedCountryId
                                                            ? countriesList.find((country: any) => country.id === selectedCountryId)
                                                            : ''
                                                    }
                                                    disableClearable
                                                    onChange={(event, newValue) => handleCountryChange(newValue)}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            inputProps={{ ...params.inputProps, readOnly: true }}
                                                            label={
                                                                <>
                                                                    Select Country{' '}
                                                                    <Box component={'span'} color={colors.astric}>
                                                                        *
                                                                    </Box>
                                                                </>
                                                            }
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                            {formErrors?.country && (
                                                <Typography variant="subtitle1" color={`${colors.error} !important`}>
                                                    {formErrors?.country}
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                    <FormHelperText error>
                                        <ErrorMessage name="country" />
                                    </FormHelperText>
                                </Stack>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6 }}>
                                <Stack className="cstVerify cstMobileNumber">
                                    <Typography variant="subtitle1" mb={1} color="info.100">
                                        {t('mobile_no')}{' '}
                                        {editProfile && (
                                            <Box component="span" color={colors.astric}>
                                                *
                                            </Box>
                                        )}
                                    </Typography>
                                    {!editProfile && (
                                        <TextFieldComponent
                                            variant="outlined"
                                            disabled
                                            required
                                            id=""
                                            error={false}
                                            size="small"
                                            isAutoFocus
                                            name="mobile_number"
                                            type="string"
                                            placeholder={`${t('enter_mobile_number')}`}
                                            value={values?.mobile_number}
                                        />
                                    )}
                                    {editProfile && (
                                        <Box display="flex" gap={1}>
                                            <FormControl className="cstSelectProfile" sx={{ width: '20%' }}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="CountryCode"
                                                    placeholder={`${t('country_code')}`}
                                                    value={selectedCountryPhoneCode}
                                                    className="cstEditProfile"
                                                    disabled
                                                >
                                                    {countriesList.map((item: any) => (
                                                        <MenuItem key={item.id} value={item.phone_code}>
                                                            {item.phone_code}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <TextFieldComponent
                                                    variant="outlined"
                                                    id=""
                                                    error={false}
                                                    size="small"
                                                    name="phoneNumber"
                                                    type="string"
                                                    placeholder={`${t('enter_mobile_number')}`}
                                                    onChange={handlePhoneNumberChange}
                                                    value={phoneNumber}
                                                />
                                            </FormControl>
                                            <FormHelperText error>
                                                <ErrorMessage name="phoneNumber" />
                                            </FormHelperText>
                                        </Box>
                                    )}
                                    <CommonModal
                                        className="cstVerifyModal"
                                        modalOpen={openVerifyMobilePopUp}
                                        header={
                                            <Stack rowGap={1} pb={2}>
                                                <Typography variant="h5" color="secondary">
                                                    {t('otp_verification')}
                                                </Typography>
                                                <Typography variant="h6" color="info.main">
                                                    {t('enter_otp_received_to_new_number')}
                                                </Typography>
                                            </Stack>
                                        }
                                        cancelBtnName={t('cancel')}
                                        AcceptBtnName={t('verify')}
                                        closePopup={() => handleCloseVerifyPopUp()}
                                        acceptBtn={() => {
                                            validateOtp({
                                                userAttributeKey: 'phone_number',
                                                confirmationCode: otpValue,
                                            });
                                        }}
                                        children={
                                            <Stack>
                                                <OtpInput
                                                    isValidOtp={isValidOtp}
                                                    showText
                                                    type="password"
                                                    autoFocus
                                                    length={6}
                                                    onChange={handleOtpChange}
                                                />
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    <Typography variant="subtitle1" color={colors.gray}>
                                                        {t('otp_not_recieved_message')}
                                                    </Typography>
                                                    <ButtonComponent
                                                        className="cstResendBtn"
                                                        variant="text"
                                                        size="medium"
                                                        buttonName={t('otp_resend_button_title')}
                                                        disabled={!isResendEnabled}
                                                        onClick={isResendEnabled ? resendOtp : void 0}
                                                    />
                                                    <Typography variant="h6" className="timer">
                                                        {timer ? formatTime(timer) : ''}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        }
                                    />
                                    {!isValidPhoneNumber && (
                                        <FormHelperText error>
                                            <Typography variant="subtitle1" color={`${colors.error} !important`}>
                                                {t('invalid_phone_number')}
                                            </Typography>
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>

                            <Grid size={{ xs: 12, sm: 6 }} display="flex" alignItems="flex-end">
                                <Stack width="100%">
                                    {!editProfile && (
                                        <>
                                            <Typography variant="subtitle1" mb={1} color="info.100">
                                                {t('state')}
                                            </Typography>
                                            <TextFieldComponent
                                                variant="outlined"
                                                disabled={!editProfile}
                                                required
                                                id=""
                                                error={false}
                                                size="small"
                                                isAutoFocus
                                                name="state"
                                                type="text"
                                                value={state || '-'}
                                            />
                                        </>
                                    )}
                                    {editProfile && (
                                        <FormControl className="cstSelectProfile cstState">
                                            <Autocomplete
                                                options={stateList}
                                                getOptionLabel={(option: any) => option.name || ''} // Specify the display label
                                                value={
                                                    selectedStateId
                                                        ? stateList.find((state: any) => state.id === selectedStateId)
                                                        : ''
                                                }
                                                onChange={(event, newValue) => handleStateChange(newValue)} // Handle selection
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        label={
                                                            <>
                                                                Select State{' '}
                                                                <Box component="span" color={colors.astric}>
                                                                    *
                                                                </Box>
                                                            </>
                                                        }
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    )}
                                    {/* {formErrors?.state && (
                                        <Typography variant="subtitle1" color={`${colors.error} !important`}>
                                            {formErrors?.state}
                                        </Typography>
                                    )} */}
                                    <FormHelperText error>
                                        <ErrorMessage name="state" />
                                    </FormHelperText>
                                </Stack>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6 }}>
                                <Stack>
                                    <Typography variant="subtitle1" mb={1} color="info.100">
                                        {t('city')}{' '}
                                        {editProfile && (
                                            <Box component="span" color={colors.astric}>
                                                *
                                            </Box>
                                        )}
                                    </Typography>
                                    <TextFieldComponent
                                        variant="outlined"
                                        disabled={!editProfile}
                                        id=""
                                        error={false}
                                        size="small"
                                        isAutoFocus
                                        name="city"
                                        type="text"
                                        placeholder={`${t('enter_city')}`}
                                        onChange={(e: any) => setInitialForm({ ...initialForm, city: e.target.value })}
                                        value={initialForm.city}
                                    />
                                    <FormHelperText error>
                                        <ErrorMessage name="city" />
                                    </FormHelperText>
                                </Stack>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6 }}>
                                <Stack>
                                    <Typography variant="subtitle1" mb={1} color="info.100">
                                        {t('building_address')}{' '}
                                        {editProfile && (
                                            <Box component="span" color={colors.astric}>
                                                *
                                            </Box>
                                        )}
                                    </Typography>
                                    <TextFieldComponent
                                        variant="outlined"
                                        disabled={!editProfile}
                                        id=""
                                        error={false}
                                        size="small"
                                        isAutoFocus
                                        name="street"
                                        type="text"
                                        placeholder={`${t('enter_address')}`}
                                        onChange={(e: any) =>
                                            setInitialForm({ ...initialForm, street: e.target.value })
                                        }
                                        value={initialForm.street}
                                    />
                                    <FormHelperText error>
                                        <ErrorMessage name="street" />
                                    </FormHelperText>
                                </Stack>
                            </Grid>
                            <Grid size={12}>
                                <Stack>
                                    <Typography variant="subtitle1" mb={1} color="info.100">
                                        {t('zip_code')}
                                        {editProfile && (
                                            <Box component="span" color={colors.astric}>
                                                *
                                            </Box>
                                        )}
                                    </Typography>
                                    <TextFieldComponent
                                        variant="outlined"
                                        disabled={!editProfile}
                                        id=""
                                        error={false}
                                        size="small"
                                        isAutoFocus
                                        name="zipCode"
                                        type="text"
                                        placeholder={`${t('enter_zip_code')}`}
                                        onChange={(e: any) =>
                                            setInitialForm({ ...initialForm, zipCode: e.target.value })
                                        }
                                        value={initialForm.zipCode}
                                    />
                                    <FormHelperText error>
                                        <ErrorMessage name="zipCode" />
                                    </FormHelperText>
                                </Stack>
                            </Grid>
                            {matches && (
                                <Grid size={12}>
                                    <ActionItems submitForm={submitForm} />
                                </Grid>
                            )}
                            {open && <ProfileSetPassword open={open} closePopup={() => setOpen(false)} />}
                        </Grid>

                        <Box className="cstProfileImage">
                            <Grid>
                                <Grid size={12}>
                                    <Stack className={!(croppedImageUrl || profileImageUrl) && profile({ theme }) ? "cstProfileImageStack cstEmptyProfile" : "cstProfileImageStack"}>
                                        <CommonModal
                                            className="cstProfileModal"
                                            header={t('drag_image_to_adjust')}
                                            cancelBtnName={t('cancel')}
                                            AcceptBtnName={t('select')}
                                            modalOpen={openModal}
                                            closePopup={() => {
                                                setImageSrc(null);
                                                setImage(null);
                                                setOpenModal(false);
                                            }}
                                            acceptBtn={() => {
                                                if (!crop.width || !crop.height || !image) {
                                                    dispatch(
                                                        openToast({
                                                            show: true,
                                                            message: t('please_crop_and_proceed'),
                                                            severity: 'error',
                                                            isAlertOpen: true,
                                                        }),
                                                    );
                                                    return;
                                                }
                                                cropImageNow(setFieldValue);
                                                setOpenModal(false);
                                            }}
                                        >
                                            {imageSrc && (
                                                <Box>
                                                    <ReactCrop
                                                        crop={crop}
                                                        onChange={newCrop => {
                                                            if (!initialCrop) return;

                                                            const minCropWidth = initialCrop.width;
                                                            const minCropHeight = initialCrop.height;

                                                            const adjustedCrop = {
                                                                ...newCrop,
                                                                width: Math.max(newCrop.width, minCropWidth),
                                                                height: Math.max(newCrop.height, minCropHeight),
                                                                x: Math.min(newCrop.x, crop.x + crop.width - minCropWidth),
                                                                y: Math.min(newCrop.y, crop.y + crop.height - minCropHeight),
                                                            };

                                                            setCrop(adjustedCrop);
                                                        }}
                                                        aspect={1}
                                                        onComplete={(c: PixelCrop) => setCrop(c)}
                                                    >
                                                        <img
                                                            src={imageSrc}
                                                            alt="Source"
                                                            onLoad={e => {
                                                                const img = e.currentTarget;

                                                                const minDimension = Math.min(img.width, img.height);

                                                                const calculatedInitialCrop: Crop = {
                                                                    unit: 'px',
                                                                    width: minDimension * 0.5,
                                                                    height: minDimension * 0.5,
                                                                    x: (img.width - minDimension * 0.5) / 2,
                                                                    y: (img.height - minDimension * 0.5) / 2,
                                                                };

                                                                setCrop(calculatedInitialCrop);
                                                                setInitialCrop(calculatedInitialCrop);
                                                                setImage(img);
                                                            }}
                                                            style={{ maxWidth: '100%' }}
                                                        />
                                                    </ReactCrop>
                                                </Box>
                                            )}
                                        </CommonModal>
                                        {!(croppedImageUrl || profileImageUrl) && profile({ theme }) ? <Box className='emptyBox'>
                                            <img
                                                src={profile({ theme })}
                                                alt="emptyProfile"
                                                width="100%"
                                                height="100%"
                                            />
                                        </Box> :
                                            <img
                                                src={croppedImageUrl || profileImageUrl}
                                                alt='Profile'
                                                width="100%"
                                                height="100%"
                                            />}
                                        {editProfile && (
                                            <Box
                                                className="cstProfileImageEdit"
                                                onClick={() => {
                                                    imgRef.current?.click();
                                                }}
                                            >
                                                <IconButton>
                                                    <PermIdentityIcon sx={{ fontSize: 40 }} />
                                                </IconButton>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    ref={imgRef}
                                                    style={{ display: 'none' }}
                                                    onChange={e => {
                                                        const file = e.target.files ? e.target.files[0] : null;
                                                        if (file) {
                                                            selectImage(file);
                                                            setOpenModal(true);
                                                            e.target.value = '';
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        )}
                                    </Stack>
                                </Grid>
                                {!matches && <ActionItems submitForm={submitForm} />}
                            </Grid>
                        </Box>
                    </Form>
                )}
            </Formik>
        </MainWrapper>
    );
}
