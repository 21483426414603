export const environment = {
    ENVIRONMENT: 'local',
    USER_POOL_CLIENT_ID: '2iqisrl45netbepu0ih3paea4r',
    USER_POOL_ID: 'us-east-1_yftnVQ7Q0',
    IDENTITY_POOL_ID: 'us-east-1:34557cbd-db41-436f-b54d-b3266344c926',
    OAUTH_DOMAIN: 'watter-dev.auth.us-east-1.amazoncognito.com',
    REDIRECT_SIGN_IN_URLS: 'https://customer.dev.wattertechnologies.com/home',
    REDIRECT_SIGN_OUT_URLS: 'https://customer.dev.wattertechnologies.com',
    // REDIRECT_SIGN_IN_URLS: 'http://localhost:3000/home',
    // REDIRECT_SIGN_OUT_URLS: 'http://localhost:3000',
    S3_BUCKET: '296062565941-account-profile-dev',
    APP_REGION: 'us-east-1',
    S3_IMAGE_BASE_URL: 'https://watter-dev.s3.us-east-1.amazonaws.com',
    API_END_POINT: 'https://dev.api.wattertechnologies.com/graphql',
};
